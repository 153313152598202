<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <div style="display: none;">
      <div
        id="landmark"
        class="text-center"
      >
        <v-card
          dark
          :style="showPopup"
        >
          <div class="px-3 pb-3 pt-1 text-left">
            <div class="text-right me-n2">
              <v-icon
                color="red"
                @click="togglePopup()"
              >
                mdi-close
              </v-icon>
            </div>
            <div class="div-overflow-y">
              <p v-if="map.geocoderResponse">
                <b>{{ map.placeAddress }}</b><br>
              </p>
              <p class="pt-1">
                <i>
                  {{ $t('Ticketing.latitude') }} {{ map.coordsLonLat[1] }}
                  <br>
                  {{ $t('Ticketing.longitude') }} {{ map.coordsLonLat[0] }}
                  <br>
                  {{ $t(map.rasterMarkerLabel) }}{{ map.rasterMarkerValue }}{{ $t(map.rasterMarkerUdm).replace('(', '').replace(')', '') }}
                </i>
              </p>
            </div>
          </div>
        </v-card>
        <div
          v-if="map.isCenterMarker"
          v-blur
          class="icon"
          @click="togglePopup()"
        >
          <i />
        </div>
        <v-icon
          v-else
          v-blur
          x-large
          color="black"
          @click="togglePopup()"
        >
          mdi-map-marker
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      overlay: false
    };
  },
  computed: {
    ...mapState(['map']),
    showPopup() {
      return this.map.isPopupMarker ? '' : 'display: none';
    }
  },
  watch: {
    $route() {}
  },
  mounted() {
  },
  methods: {
    ...mapActions(['closePopupOnMap']),
    togglePopup() {
      this.map.isPopupMarker = !this.map.isPopupMarker;
      this.map.landmark.panIntoView();
      this.closePopupOnMap();
      this.map.select.getFeatures().clear();
    }
  }
};
</script>

<style scoped>
@-webkit-keyframes anim-glow {
  0% {
    box-shadow: 0 0 #4285F4;
  }
  100% {
    box-shadow: 0 0 10px 15px transparent;
  }
}
@keyframes anim-glow {
  0% {
    box-shadow: 0 0 #4285F4;
  }
  100% {
    box-shadow: 0 0 10px 15px transparent;
  }
}
#landmark > .v-icon {
  text-shadow: 0px 3px 5px #333;
}
.v-card {
  max-width: 450px;
  margin-bottom: 15px;
  /* outline-offset: -5px;
  outline: 3px solid #00A644; */
  opacity: 0.95;
  font-family: 'Open Sans', sans-serif;
}
.v-card::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  opacity: 0.90;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
.div-overflow-y{
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.icon {
  border-radius: 50%;
  border: 2px solid #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  -webkit-animation: anim-glow 2s ease infinite;
          animation: anim-glow 2s ease infinite;
}
.icon i {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: inline-block;
  opacity: 0.90;
  background-color: #4285F4;
}
</style>
